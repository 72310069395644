import SiteHeader from './SiteHeader.jsx';
import './App.css';

function App() {
  return (
    <div className="App">
      <SiteHeader />
    </div>
  );
}

export default App;
